import React, { useEffect } from "react";
import {
  PlanoImg,
  PlanoImgVertical,
  PlanoPdf,
  PlanoPdf2,
} from "../../assests/plano";

const PlanBody = () => {
  const isMobile = window.innerWidth;
  useEffect(() => {
    window.scroll({
      top: 0, 
      left: 0,
      behavior: 'smooth' 
    });
  }, []);

  return (
    <div className="container">
      <div className="row mt-5">
        <div
          className="col-md-12 col-12 d-flex align-items-center"
          style={{ marginBottom: "14px" }}
        >
          <div>
            <h1 className="sectionName m-0">Planos</h1>
          </div>
          <div className="mx-2">
            <a
              className="fw-bold"
              download="Plano-feria"
              href={isMobile <= 768 ? PlanoPdf2.default : PlanoPdf.default}
            >
              Descargar plano
            </a>
          </div>
        </div>
        <hr />
      </div>

      <div className="row d-flex flex-column">
        {isMobile <= 768 ? (
          <img alt="plano-feria-vertical" src={PlanoImgVertical.default} />
        ) : (
          <img alt="plano-feria" src={PlanoImg.default} />
        )}
      </div>
    </div>
  );
};

export default PlanBody;
