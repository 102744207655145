import React, { useEffect, useCallback, useState } from "react";
import { Pagination } from "../Pagination";
import { JobSearch } from "./JobSearch";
import JobsService from "../../axios/services/JobsService";
import { Link, useLocation } from "react-router-dom";
import Loading from "../Loading";

export const JobDetail = () => {
  const { search } = useLocation();
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState();

  const fetchJobs = useCallback(
    async (page = undefined, limit = undefined) => {
      setLoading(true);

      return JobsService.getJobs(search, page, limit)
        .then((response) => response.data)
        .then(({ filters, results: { data, ...pagination } }) => {
          setJobs({
            filters,
            results: { data, pagination: { ...pagination } },
          });
        })
        .catch((error) => {
          setJobs({ data: undefined, pagination: {} });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [search, setLoading]
  );

  useEffect(() => {
    fetchJobs();
    window.scroll({
      top: 0, 
      left: 0,
      behavior: 'smooth' 
    });
  }, [fetchJobs]);

  const titleMatch = {
    "?school_level_id_filter=1": "Educación inicial",
    "?school_level_id_filter=2": "Educación Primaria",
    "?school_level_id_filter=3": "Educación Secundaria",
    "?school_level_id_filter=4": "Educación Superior",
    "?international=1": "Trabajos internacionales",
    "": "Todos los trabajos",
  };


  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-md-12 col-12 d-flex">
          <div className="mx-2">
            <Link to="/">Inicio</Link>
            <span>{' '}/</span>
          </div>
          <h1 className="sectionName">
            Trabajos |{" "}
            {
              titleMatch[
                Object.keys(titleMatch).find((query) => search.includes(query))
              ]
            }
          </h1>
          <hr />
        </div>
      </div>

      <JobSearch jobs={jobs} />

      {loading || (jobs && Object.keys(jobs?.results ?? []).length === 0) ? (
        <Loading />
      ) : (
        <>
          <div className="row justify-content-center">
            {(jobs?.results?.data?.length ?? 0) > 0 ? jobs?.results?.data?.map((data, index) => (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-12 mt-4"
                key={index}
              >
                <Link to={`/trabajos/${data?.id}/detalle`}>
                  <div className="card ferias">
                    <div className="card-header">
                      <img
                        className="card-img"
                        src={data?.multimedia?.image_1?.url}
                        width="100%"
                        alt="Ferias"
                      />
                    </div>
                    <div className="card-body">
                      <h1 className="mb-2">{data?.title}</h1>
                      <p className="mb-2">{ (data?.country?.toLowerCase() !== 'argentina' && data?.country !== null) ? data?.country : data?.jurisdiction }</p>
                      <hr />
                      <p>
                        <strong>Nivel:</strong>{" "}
                        {data?.mainData?.schoolLevel?.name}
                      </p>
                      <p>
                        <strong>Modalidad:</strong>{" "}
                        {data?.mainData?.gradingModality?.name}
                      </p>
                      <p>
                        <strong>Foco:</strong>{" "}
                        {data?.mainData?.curricularFocus?.name}
                      </p>
                    </div>
                    <div className="card-footer">
                      <Link
                        className="btn btn-secondary w-100"
                        to={`/trabajos/${data?.id}/detalle`}
                      >
                        Ver más
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            )) : (
              <div
                className="col-xl-3 col-lg-4 col-md-6 col-12 mt-5 text-center"
              >
                <span className="text-bold">No hay resultados para su busqueda</span>
              </div>
            )}
          </div>
          <Pagination
            metadata={jobs?.results?.pagination}
            onPageChange={fetchJobs}
          />{" "}
        </>
      )}
    </div>
  );
};
